<template>
  <acne-scars-template
    :face-scars-appearance="faceScarsAppearance"
    :title="facialScarsTitle"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import AcneScarsTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-appearance/acne-scars/AcneScarsTemplate';
import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { getScarCauses } from '@/modules/questionnaire/utils';

const { field, requiredField } = fieldBuilder;

const FIELDS = [requiredField('faceScarsAppearance'), field('acneScarCauses')];

export default {
  name: 'AcneScars',
  components: {
    AcneScarsTemplate
  },
  mixins: [makeStep(FIELDS)],
  computed: {
    acneScarTypes() {
      return getScarCauses(this.acneScarCauses);
    },
    facialScarsTitle() {
      return this.$i18n.t('label.faceScarsAppearance', [
        this.$i18n.t(`scarCause.${this.acneScarTypes}`)
      ]);
    }
  },
  watch: {
    faceScarsAppearance() {
      this.showNextStep();
    }
  }
};
</script>
